<template>
	<div class='' ref="homeDom">
		<!-- 首页大banner -->
		<div class="banner_swiper">
			<el-carousel  indicator-position='none'>
				<el-carousel-item v-for="item in bannerImg" :key="item.id">
					<img :src="item.pcPic" class="w-max h-max" @click="jumpD(item)">
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- //首页大banner -->

		<!-- 产品中心 -->
		<div class="goods_box">
			<!-- 产品中心轮播图 -->
			<div class="goods_swiper">
				<GoodsSwiper2 />
			</div>
			<!-- 	<div class="goods_swiper">
				<GoodsSwiper style="z-index: 999;"></GoodsSwiper>
			</div> -->
			<!-- //产品中心轮播图 -->
			<!-- 产品中心文案 -->
			<div class="container pl550 flex row-center mt50 fs18 mb300" style="color: #9D9D9D;">
				<!-- 左 -->
				<div class="text-right mr202 goodsBox">
					<div class="fs18">{{'Product Center'.toUpperCase()}}</div>
					<div class="fs32 fw-600 mt8" style="color:#272727">产品中心</div>
					<div class="mt24 fs18" style="text-indent:2em">
						<div>安全型消毒剂</div>
						<div class="mt8">智慧防疫设备</div>
						<div class="mt8">综合公共卫生管理系统</div>
					</div>
				</div>
				<!-- //左 -->
				<!-- 右 -->
				<div class="w720">
					<div class="lh45 fs18">
						铭智生科拥有强大的研发能力，产品线涉及微酸性碳酸型次氯酸消毒产品，免激活一元性二氧化氯消毒产品，基于物联网技术及自有专利技术的等离子体、水离子智慧防疫设备，综合公共卫生管理系统等，通过丰富的多元化产品，组建起全维度的公共卫生服务体系。
					</div>
					<div style="color: #F7B500;" class="fs18 fw-600 mt47 pointer" @click="$router.push('/goodsCenter')">
						查看更多>
					</div>
				</div>
				<!-- //右 -->
			</div>
			<!-- //产品中心文案 -->
		</div>
		<!-- //产品中心 -->

		<!-- 新闻与活动 -->
		<div >
			<NewsAndActive :newsList='newsList'></NewsAndActive>
		</div>

		<!-- //新闻与活动 -->

		<!-- 宣传视频 -->
		<div class="w-max video_box flex row-center col-center">
			<!-- <video src="http://download.e-rijiben.com/movie/mzbiotech.mp4" class="w-max h-max" controls loop preload></video> -->
			<video src="http://download.e-rijiben.com/movie/mzbiotech.mp4" controls loop preload autoplay muted
				class="play_box"></video>
		</div>
		<!-- //宣传视频 -->

		<!-- 合作案例 -->
		<div>
			<CooperationCase />
		</div>
		<!-- //合作案例 -->

		<!-- 团队介绍 -->
		<div id="team">
			<TeamInfo />
		</div>
		<!-- //团队介绍 -->

		<!-- 为什么选择我们? -->
		<div class="h832" id="serve">
			<div class="container pt132 pb150 fs18 color9D">
				<div class="text-center">{{'Why choose us?'.toUpperCase()}}</div>
				<div class="text-center mt8 mb24 fs32 fw-600 color3">为什么选择我们?</div>
				<div class="text-center w850 lh45 fs18" style="margin: 0 auto;">
					完整的防疫体系、优质的服务、权威的检测报告、独有的发明专利。铭智生科将为您在公共卫生防疫的领域内保驾护航。</div>
				<div class="mt160 color0 flex row-between pr171 pl137">
					<div class="text-center" v-for="(item,i) in serveList" :key="i">
						<div class="fs32 fw-600">{{item.num}}</div>
						<div class="fs18 mt32">{{item.title}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //为什么选择我们? -->
		<!-- 侧边锚点定位 -->
		<div class="sidebar">
			<div class="sidebar_box mb20" v-for="(item,i) in maoDiaoList" :key="i" @click="goTo(item,i)"
				:class="{'active':active==i}"></div>
		</div>
		<!-- //侧边锚点定位 -->
	</div>
</template>

<script>
	//商品轮播图
	import GoodsSwiper from '@/components/GoodsSwiper.vue'
	import GoodsSwiper2 from '@/components/goodsSwiper2.vue'
	//新闻与活动组件
	import NewsAndActive from '@/views/home/components/NewsAndActive.vue'
	import CooperationCase from '@/views/home/components/CooperationCase.vue'
	import TeamInfo from '@/views/home/components/TeamInfo.vue'
	import { getForwardBanners, getNewsAndActivity } from '@/api/index'
	export default {
		name: 'home',
		components: {
			GoodsSwiper,
			NewsAndActive,
			CooperationCase,
			TeamInfo,
			GoodsSwiper2
		},
		data() {
			return {
				// banner列表
				bannerImg: [],
				serveList: [{
					num: '1000000+',
					title: '服务人群'
				}, {
					num: '100+',
					title: '服务城市'
				}, {
					num: '99.99%',
					title: '产品好评率'
				}, {
					num: '99.99%',
					title: '服务好评率'
				}, ],

				maoDiaoList: [{
					jump: 0
				}, {
					jump: 1200
				}, {
					jump: 2140
				}, {
					jump: 3380
				}, {
					jump: 4420
				}, {
					jump: 5820
				}, {
					jump: 6920
				}, ],
				active: 0,
				count: 0,
				scrollTop: 0,
				newsList: []
			};
		},
		created() {
			this.$store.commit('editRouterName', this.$route.name)
			this.getBannerSwiper()
			//获取首页新闻列表
			this.getNewsAndActivity()
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
		},

		methods: {
			//banner轮播图
			async getBannerSwiper() {
				const res = await getForwardBanners({ bannerType: 1 })
				this.bannerImg = res.data
			},
			//获取首页新闻活动
			async getNewsAndActivity() {
				const res = await getNewsAndActivity()
				this.newsList = res.data
			},
			//轮播图跳转
			jumpD(item) {
				if (item.isForward == 1) {
					let url = ''
					if (item.forwardHead == 1) {
						url = '/coreJiShuD' //核心技术详情
					} else if (item.forwardHead == 2) {
						url = '/goodsD' //产品中心详情
					} else if (item.forwardHead == 3) {
						url = '/solutionD' //解决方案详情
					} else if (item.forwardHead == 5) {
						url = '/patentD' //专利报告
					} else if (item.forwardHead == 7) {
						url = '/newsD' //新闻详情
					}
					this.$router.push(`${url}/${item.forwardContentId}`)
				}
			},
			// 获取页面滚动距离
			handleScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				if (this.scrollTop < 1200) {
					this.active = 0
				} else if (this.scrollTop < 2140) {
					this.active = 1
				} else if (this.scrollTop < 3380) {
					this.active = 2
				} else if (this.scrollTop < 4420) {
					this.active = 3
				} else if (this.scrollTop < 5820) {
					this.active = 4
				} else if (this.scrollTop < 6920) {
					this.active = 5
				} else {
					this.active = 6
				}
				// this.$refs.homeDom.scrollTop = 距离
			},
			//跳转到指定高度
			goTo(item, i) {
				this.active = i
				document.documentElement.scrollTop = item.jump
			}
		},
		// 滚动重置
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>
<style lang='scss' scoped>
	.banner_swiper {
		::v-deep .el-carousel__container {
			height: 800px;
		}
		::v-deep .el-carousel__item.is-active {
			z-index: 0;
		}
	}

	.goods_box {
		.goods_swiper {
			margin-top: -159px;
			transform: translateX(55.3%);
		}
	}
	.goodsBox {
		margin-top: -100px;
	}

	.sidebar {
		position: fixed;
		top: 50%;
		right: 50px;
		z-index: 999;

		.sidebar_box {
			display: block;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background: rgba(0, 0, 0, 0.4);
			cursor: pointer;
		}

		.active {
			width: 16px;
			height: 40px;
			background: #f7b500;
			border-radius: 40px;
		}
	}

	.video_box {
		height: 1080px;
		background: url("~@/assets/images/home/video.png") no-repeat;
		background-size: 100% 100%;

		.play_box {
			width: 1200px;
			height: 675px;
			background: #000000;
			border: 12px solid #ffffff;
		}
	}
</style>
