<template>
  <div class='container'>
    <div class="container_box w-max flex">
      <!-- 左边 -->
      <div class="flex row-column col-end pl249">
        <!-- 合作案例 -->
        <div class="demo_box fs18 color-0 pt60 pb89 pl84">
          <div>{{'Cooperation case'.toUpperCase()}}</div>
          <div class="fs32 fw-600 mt8 mb12">合作案例</div>
          <div>铭智生科大型合作案例展示</div>
        </div>
        <!-- //合作案例 -->

        <div class="w448 text-right mt450 fs18 color9D mr120">
          <div class="fs18">{{'Cooperative partner'.toUpperCase()}}</div>
          <div class="fs32 fw-600 mt8" style="color:#272727">合作伙伴</div>
          <div class="mt24 text-left lh45" style="text-indent:2em">
            铭智生科致力于公共卫生领域与科学防疫领域，经过多年的诚信经营、依靠优质的服务体系和过硬的产品质量，收获了各个领域的企事业与机关单位的支持信任。</div>
        </div>
      </div>
      <!-- //左边 -->
      <!-- 右边 -->
      <div class="flex flex-wrap ml50 mt130 pb160">
        <div class="brand_item mt30 pt15 pb15 flex col-center row-center mr30" v-for="(item,i) in imgList" :key="i">
          <img :src="item.img" class="" style="object-fit: cover;">
        </div>
      </div>
      <!-- //右边 -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgList: [{
        img: require('@/assets/images/partner/12306.webp')
      }, {
        img: require('@/assets/images/partner/beijing.webp')
      }, {
        img: require('@/assets/images/partner/chongqing.webp')
      }, {
        img: require('@/assets/images/partner/guoneng.webp')
      }, {
        img: require('@/assets/images/partner/guoyao.webp')
      }, {
        img: require('@/assets/images/partner/lepinhui.webp')
      }, {
        img: require('@/assets/images/partner/lepu.webp')
      }, {
        img: require('@/assets/images/partner/luhe.webp')
      }, {
        img: require('@/assets/images/partner/nongyedaxue.webp')
      }, {
        img: require('@/assets/images/partner/quanjude.webp')
      }, {
        img: require('@/assets/images/partner/red.webp')
      }, {
        img: require('@/assets/images/partner/shoulv.webp')
      },
      {
        img: require('@/assets/images/partner/yidong.webp')
      },
      {
        img: require('@/assets/images/partner/yuanxin.webp')
      }, {
        img: require('@/assets/images/partner/zhejiangyiliao.webp')
      },
        // {
        // img: require '@/assets/images/partner/yidong.webp'
        // }, {
        // img: require '@/assets/images/partner/zhejiangyiliao.webp'
        // }
      ]
    };
  },

  components: {},

  created () { },

  mounted () { },

  methods: {}
}
</script>
<style lang='scss' scoped>
.container_box {
  /* height: 1280px;	 */
  .demo_box {
    margin-top: -123px;
    width: 400px;
    height: 200px;
    background: #f7b500;
  }

  .brand_item {
    width: 260px;
    height: 168px;
     background-color: #f8f8f8 !important;
  }

  .brand_item:nth-child(3n+0) {
    margin-right: 0 !important;
  }
}
</style>
