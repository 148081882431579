<template>
	<div class='w-max news_content' style="background: #F9F9F9; border:1px solid transparent">
		<div class="container flex">
			<img src="@/assets/images/home/news.jpg" class="w775 mr82 newsImg" style="margin-top: -160px;object-fit: cover;">
			<div class="relative">
				<div class="mt90 color9D fs18"> {{'News and events'.toUpperCase()}}</div>
				<div class="color-3 mt8 mb24 fs32 fw-600">新闻与活动</div>
				<div class="color9D fs18">铭智生科最新资讯</div>
				<!-- 新闻列表 -->
				<div>
					<div class="mt40 flex news_box" v-for="(item,i) in newsList" :key="i"  @click="seeD(item.id,i)">
						<img :src="item.pcPic" class="w290 h200 mr20 "/>
						<div class='h200 pb17 pt17 flex row-column row-between  pointer'>
							<div class="fs24 fw-600">{{item.contentName}}</div>
							<div class="fs18">{{item.releaseTime}}</div>
							<div class="fs18 pointer" >查看详情></div>
						</div>
					</div>
				</div>
				<!-- //新闻列表 -->
				<div class="flex absolute " style="bottom: 0;">
					<div class="more mr40" v-for="(item,i) in newTabList" :key="item.id"
						@click="$router.push(`/news/${item.id}`)">更多{{item.categoryName}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getNewsCategory } from '@/api'
	export default {
		props: {
			newsList: {}
		},
		data() {
			return {
				active: 0,
				newTabList: []
			};
		},

		components: {},

		created() {
			this.getNewsCategory()
		},

		mounted() {},

		methods: {
			async getNewsCategory() {
				const res = await getNewsCategory()
				this.newTabList = res.data
			},
			seeD(id, i) {
				this.active = i
				this.$router.push(`/newsD/${id}`)
			}
		}
	}
</script>
<style lang='scss' scoped>
	.news_content {
		height: 1050px;
	}

	.more {
		width: 180px;
		height: 50px;
		border: 2px solid #272727;
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #272727;
		text-align: center;
		line-height: 50px;
		cursor: pointer;
	}

	.more:hover {
		border: 2px solid #f7b500;
		background: #f7b500;
		color: #fff;
	}

	.news_box :hover {
		color: #f7b500 !important;
	}

	.newsImg {
		height: 1000px !important;
	}
</style>
