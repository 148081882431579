<template>
	<!-- Swiper -->
	<div>
		<div class="swiper-container" v-if="show">
			<div class="swiper-wrapper">
				<div class="swiper-slide flex row-center col-center relative pointer" v-for="(item,i) in goodsList"
					:key="i">
					<!-- @click="$router.push(`/goodsD/${item.id}`)" -->
					<img :src="item.pcPic">
					<div class="w202 h80 absolute goods_content flex row-column text-left row-center pl20 pr20 color-f"
						style="background: rgba(39, 39, 39, .8); z-index: 999;">
						<div class="fs14">{{item.bannerName}}</div>
						<div class="fs18 mt4">{{item.goodsName}}</div>
					</div>
				</div>
			</div>
			<!-- Add Pagination -->
			<!-- <div class="swiper-pagination"></div> -->
			<!-- 		<div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div> -->
		</div>
	</div>
</template>

<script>
	import { getForwardBanners } from '@/api/index'

	import Swiper from 'swiper'
	import 'swiper/css/swiper.min.css'
	export default {
		// 组件名称
		name: 'GoodsSwiper',
		// 组件状态值
		data() {
			return {
				//产品中心列表
				goodsList: [],
				show: false
			}
		},
		created() {

		},
		mounted() {
			this.getGoods()
		},
		// 组件方法
		methods: {
			// 获取产品中心
			async getGoods() {
				const res = await getForwardBanners({ bannerType: 2 })
				this.goodsList = res.data
				this.show = true
				this.$nextTick(() => {
					new Swiper('.swiper-container', {
						autoplay: {
							disableOnInteraction: false,
							delay: 3000,
						},
						preventClicks: true, //默认true
						slidesPerView: 3,
						spaceBetween: 30,
						centeredSlides: true,
						allowSlidePrev: false,
						loop: true,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
						on: {
							click: (event) => {
								let src = event.target.src
								const obj = this.goodsList.find(item => {
									return item.pcPic == src
								})
								this.$router.push(`/goodsD/${obj.forwardContentId}`)
							}
						},
					});
				})
			},
			goto() {
				// this.
			}
		}
	}
</script>
<style scoped>
	.goods_content {
		bottom: 0;
		right: 0;
	}

	.swiper-container {
		/* padding-left: 20px; */
		width: 110%;
		height: 420px;
	}

	img {
		max-width: none !important;
		max-height: none !important;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: transparent;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		transition: 300ms;
	}

	.swiper-slide img {
		width: 560px;
		height: 327px;
	}

	.swiper-slide-prev img {
		transform: scale(1.28);
/* 		width: 720px !important;
		height: 420px !important; */
	}

	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}

	.swiper-button-next {
		--swiper-navigation-size: 30px;
		--swiper-navigation-color: #333;
		/* 单独设置按钮颜色 */
		left: 5%;
	}

	.swiper-button-prev {
		--swiper-navigation-size: 30px;
		--swiper-navigation-color: #333;
		/* 单独设置按钮颜色 */
		left: 0%;
	}
</style>
