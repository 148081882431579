<template>
	<!-- Swiper -->
	<!-- Swiper -->
	<div class="swiperBox relative">
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide relative" v-for="(item,i) in goodsList" :key="i">
					<img :src="item.pcPic" class="img" @click="$router.push(`/goodsD/${item.forwardContentId}`)">
					<div class="w302 h123 absolute goods_content flex row-column text-left row-center pl30 pr30 color-f"
						style="background: rgba(39, 39, 39, .8); z-index: 999;">
						<div class="fs18">{{item.bannerName}}</div>
						<div class="fw-600 fs28 mt4">{{item.goodsName}}</div>
					</div>
				</div>
			</div>
			<!-- Add Pagination -->
			<!-- <div class="swiper-pagination"></div> -->
			<div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div>
			<!-- <div class="swiper_mask"></div> -->
		</div>
	</div>

</template>

<script>
	import { getForwardBanners } from '@/api/index'
	import Swiper from 'swiper'
	import 'swiper/css/swiper.min.css'
	export default {
		// 组件名称
		name: 'GoodsSwiper',
		// 组件状态值
		data() {
			return {
				//产品中心列表
				goodsList: [],
			}
		},
		created() {

		},
		mounted() {
			this.getGoods()
		},
		// 组件方法
		methods: {
			// 获取产品中心
			async getGoods() {
				const res = await getForwardBanners({ bannerType: 2 })
				this.goodsList = res.data
				this.$nextTick(() => {
					new Swiper('.swiper-container', {
						autoplay: true,
						slidesPerView: 3,
						spaceBetween: 30,
						centeredSlides: true,
						loop: true,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
					});
				})
			},
		}
	}
</script>

<style scoped>
	.swiper {
		width: 200%;
		height: 526px !important;
	}

	.swiper-slide {
		position: relative;
		text-align: center;
		font-size: 18px;
		background: #fff;
		height: 526px !important;
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.goods_content {
		bottom: 0;
		right: 0;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		/* object-fit: cover; */
		z-index: 999;
	}

	.swiperItem-content {
		position: absolute;
		top: 62px;
		left: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		transform: translateX(-50%);
		z-index: 999;

		/* .videotit {
    margin-right: 70px;
  } */
	}

	/* 	 .swiper-wrapper {
		position: relative;
	} */
	/* 图片隐藏 */
	.swiper-slide {
		opacity: 0;
	}

	/* //左边图片隐藏 */
	/* 	 .swiper-slide-prev {
		transform: translateY(15%);
		opacity: 0;
	} */

	/* 选中图片的样式 */
	.swiper-slide-active {
		width: 720px !important;
		height: 420px !important;
		opacity: 1 !important;
	}

	.swiper-slide-active img {
		width: 100% !important;
		height: 100% !important;
	}

	.swiper-slide-prev {
		width: 560px !important;
		height: 327px !important;
	}

	.swiper-slide-prev .img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	/* //下一个图片的样式 */
	.swiper-slide-next {
		width: 560px !important;
		height: 327px !important;
		transform: translateY(15%);
		opacity: 1 !important;
	}

	.swiper-slide-next img {
		width: 100%;
		height: 100%;
	}

	.swiper-button-next {
		--swiper-navigation-size: 30px;
		--swiper-navigation-color: #333;
		/* 单独设置按钮颜色 */
		left: 25%;
	}

	.swiper-button-prev {
		--swiper-navigation-size: 30px;
		--swiper-navigation-color: #333;
		/* 单独设置按钮颜色 */
		left: 20%;
	}

	.swiper_mask {
		position: absolute;
		top: 10%;
		width: 33.8%;
		height: 400px;
		background-color: #fff;
		z-index: 2;
	}
</style>
