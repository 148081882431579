<template>
	<div class='banner color-f pt100'>
		<div class="banner_content">
			<div class="text-center fs18">{{'Team Introduction'.toUpperCase()}}</div>
			<div class="text-center fs32 fw-600 mt8 mb50">团队介绍</div>
			<div class="text-center fs18 mb60 lh45 w850" style="margin: 0 auto;">
				我们是一支由多名医疗卫生系统具有多年经验的专业人才组成的创业团队，作为国内首批从事于公共卫生领域的企业，我们有着宝贵的防疫经验与科学的管理体系，团队成员充满朝气、具有创新意识并勇于迎接挑战，愿为改善中国公共卫生现状，贡献出自己的力量。
			</div>
			<div class="swiper-container" v-if="show">
				<div class="swiper-wrapper">
					<div class="swiper-slide relative pt32 text-center" v-for="(item,i) in list" :key="i">
						<img :src="item.pcPic" class="w448 h505" style="object-fit: cover;">
						<div style="color: #F7B500;" class="team_name mt16">
							<div class="fs32 fw-600">{{item.memberName}}</div>
							<div class="fs18 mt9">{{item.memberPosition}}</div>
						</div>
						<!-- 		<div class="color3 absolute team_item flex col-center pl36 pr36 row-center">
							<span class="fs48 fw-600 mr12 lh70">{{item.memberName}}</span>
							<div class="fs30 fw-600 lh73">{{item.memberPosition}}</div>
						</div> -->
					</div>
				</div>
				<!-- Add Pagination -->
				<!-- <div class="swiper-pagination"></div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import 'swiper/css/swiper.min.css'
	import { getTeam } from '@/api'
	export default {
		data() {
			return {
				list: [],
				show: false
			};
		},

		components: {},

		created() {},

		mounted() {
			this.getTeam()

		},

		methods: {
			async getTeam() {
				const res = await getTeam()
				let obj={
					pcPic:require('@/assets/images/home/ceo.jpg'),
					memberName:'王涛',
					memberPosition:'董事长'
				}
				res.data.unshift(obj)
				this.list = res.data
				this.show = true
				this.$nextTick(() => {
					new Swiper('.swiper-container', {
						autoplay: {
							disableOnInteraction: false,
							delay: 3000,
						},
						preventClicks: true, //默认true
						slidesPerView: 3,
						spaceBetween: 30,
						centeredSlides: true,
						loop: true,
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},

					});
				})
			}
		}
	}
</script>
<style scoped>
	.banner {
		width: 100%;
		height: 1200px;
		background: #000;

	}

	.swiper-container {
		height: 100%;
	}

	.banner_content {

		margin: 0 auto;
		width: 1524px;
		/* height: 100%; */
		/* // background: skyblue !important; */

	}

	.swiper-slide {
		display: block;
		font-size: 18px;
		/* Center slide text vertically */
		/* 	display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex; */
		/* 		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center; */
		/* justify-content: center; */
		/* // -webkit-box-align: center;
		// -ms-flex-align: center;
		// -webkit-align-items: center;
		// align-items: center; */
		transition: 300ms;
		transform: scale(0.9);
		background: rgba(255, 255, 255, .19);
		height: 668px;
	}

	/* 	.team_name {
		position: absolute;
		bottom: 10px;
	} */


	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}

	.team_item {
		bottom: 0;
		left: 0;

		/* padding: 7px 36px; */
		background: #F7B500;
	}
</style>
